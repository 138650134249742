:root {
	--bgColor: #506f86;
	--color5: #e29e37;
	--color4: #405361;
	--color3: #2f3c4f;
	--color: white;
	--color2: #fbb040;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color3);
	color: var(--color);
	text-align: center;
}

.time-setter {
	width: 300px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(2, 150px);
	column-gap: 20px;
}
.time-setter button {
	background-color: transparent;
	color: white;
}
.time-setter input {
	height: 40px;
	font-size: 20px;
	font-size: 1.4em;
	font-weight: bold;
	border-radius: 30px;
	width: 95%;
}
.time-setter label {
	position: relative;
}
.time-setter label:nth-child(3)::after {
	content: ":";
	position: absolute;
	top: 7px;
	right: -13px;
	font-size: 20px;
	/* z-index: 300;
    display: block;
    width: 20px;
    height: 20px; */
}
.alarm-list > button {
	width: 300px;
	margin-bottom: 25px;
}
.alarm-container {
	position: relative;
	max-width: 425px;
	margin: 0 auto 40px;
}
.alarm-container.active {
	background: var(--color2);
	/* color: black; */
}
.alarm-now-button {
	position: absolute;
	top: 60px;
	left: 17px;
	padding: 8px;
	background: transparent;
	color: white;
	font-size: 1.2em;
}
.alarm-container .alarm-delete-button {
	position: absolute;
	top: 0;
	right: 0px;
	padding: 8px;
	background: #7b3232;
	color: #ddd;
}
.alarm-checkbox {
	opacity: 0;
	width: 0;
}
.alarm-checkbox-label i {
	font-size: 1.6em;
}
.alarm-checkbox-label {
	position: absolute;
	top: 42%;
	right: 0px;
}
.alarm-checkbox-label:hover {
	cursor: pointer;
}

/*********WINDOW*******/
.windows {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
/* .windows > div:first-child:last-child {
	border: none;
} */
.windows > div {
	border: 1px dashed var(--bgColor);
	/* flex: 1 1 30%; */
	flex: auto;
	resize: both;
	overflow: auto;
}
.windows > div.expanded {
	flex: 1 1 100%;
	border: none;
}
.windows.has-expanded > div:not(.expanded) {
	display: none;
}
/* .window {
	border: 1px dashed var(--bgColor);
	flex: auto;
	resize: both;
	overflow: auto;
}
.window.expanded {
	flex: 1 1 100%;
} */
/* .windows.has-expanded .window:not(.expanded) {
	display: none;
} */
.action-bar {
	text-align: right;
}
.action-bar > button {
	padding: 7px 10px;
	margin-left: 5px;
	background-color: var(--color4);
}
.action-button.active,
.action-bar > button.active {
	/* background-color: var(--color5); */
	color: var(--color);
}
.action-button:hover,
.action-bar > button:hover {
	background-color: var(--bgColor);
}
.action-button:active,
.action-bar > button:active {
	color: var(--color);
}
/****************/
.App {
	position: relative;
}
button {
	padding: 20px;
	background-color: var(--color2);
	border: none;
}
button:active {
	background-color: var(--color5);
	color: var(--color);
}
button:hover {
	cursor: pointer;
}
.configurationContainer {
	position: relative;
	background-color: var(--color4);
}
.configurationContainer label {
	padding: 0 5px 0 15px;
}
.configuration {
	display: none;
	padding: 20px 0 30px;
}
.configuration.show {
	display: block;
}

.configuration > div {
	margin: 20px 0 40px;
}
.configuration > div:last-child {
	margin-bottom: 15px;
}
.configuration p {
	margin-bottom: 5px;
}
.paragraph-down {
	margin-bottom: -5px;
}
.action-button {
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: var(--color4);
	font-size: 1.5em;
	padding: 11px 15px;
}
.config-controls {
	margin-top: 10px;
}
.config-controls input {
	width: 70px;
	height: 51px;
	font-size: 2.5em;
	line-height: 2em;
	vertical-align: bottom;
	border-radius: 0;
	padding: 1px 15px;
	text-align: center;
}
.config-controls button {
	font-size: 30px;
	padding: 10px 20px;
	min-width: 70px;
}
.dices-config {
	margin: 60px 0;
}
.dices {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin: 60px 0;
	flex-wrap: wrap;
}
.dices span {
	font-size: 70px;
	display: block;
}
.dice {
	display: inline-block;
}
.reroll-all {
	margin: 40px 0;
	width: 300px;
}
.row {
	display: flex;
	justify-content: center;
	column-gap: 40px;
}
.column {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}
input {
	text-align: center;
}
