:root {
	--bgColor: #506f86;
	--color6: #243039;
	--color5: #e29e37;
	--color4: #405361;
	--color3: #2f3c4f;
	--color: white;
	--color2: #fbb040;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.AppAll {
	padding-top: 50px;
}
.main-header {
	position: fixed;
	display: flex;
	justify-content: space-between;
	background-color: var(--color6);
	width: 100%;
	top: 0;
	z-index: 99;
}
.main-header .logo {
	padding: 5px 0;
}
.main-header nav a {
	color: var(--color2);
	text-decoration: none;
	padding: 5px 10px;
	display: inline-block;
}
.main-header nav a:hover {
	color: var(--color);
	background: var(--color4);
}
